@import "primereact/resources/themes/lara-light-indigo/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";


@media screen and (max-width: 600px) {
    .search-input-control {
        width: 100% !important;
    }
    .p-inline-message {
        font-size: 12px;
    text-align: center;
    }
}