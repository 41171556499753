.chat-ptc {
    box-shadow: rgb(0 18 46 / 16%) 0px 8px 36px 0px; 
    max-height: calc(100% - 47px);
    display: flex;
    flex-direction: column;
    width: 372px;
    position: absolute;
    bottom: 26px;
    border-radius: 16px;
    pointer-events: auto;
    overflow: hidden;
    z-index: 1;
    right: 48px;
    left: auto;
    font-family: Mulish, sans-serif;
    letter-spacing: -0.24px;
    -webkit-font-smoothing: antialiased;
}
.chat-header-ptc {
    color: rgb(255, 255, 255);
    background: linear-gradient(135deg, rgb(42, 39, 218) 0%, rgb(0, 204, 255) 100%);
    padding: 24px 28px 0px;
    position: relative;
    z-index: 4;
    flex: 0 0 auto;
}
#conversation-group-ptc {
    padding: 0px 28px;
    width: 100%;
    height: 357px;
    overflow: hidden auto;
    background: rgb(255, 255, 255);
    transition: all 0.3s ease 0s;
    max-height: 357px;
    min-height: 160px;
    flex: 0 1 auto;
}
#messages-ptc {
    position: relative;
    margin-top: 10px;
    width: 100%;
    padding-bottom: 24px;
    float: left;
}
.history-button-wrapper-ptc {
    clear: both;
    width: 100%;
    float: left;
}
.message-operator-ptc {
    color: rgb(6, 19, 43);
    background: rgb(240, 242, 247);
    float: left;
    transition: transform 0.2s ease 0s, margin 0.2s ease 0s;
}
.message-ptc {
    padding: 10px 16px;
    border-radius: 20px;
    margin: 2px 0px;
    font-size: 15px;
    line-height: 20px;
    overflow-wrap: break-word;
    display: inline-block;
    max-width: 85%;
    clear: both;
    position: relative;
    transition: margin 0.28s ease-in-out 0s;
}
.message-visitor-ptc {
    color: rgb(255, 255, 255);
    background: linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255));
    transition: transform 0.2s ease 0s, margin 0.2s ease 0s;
    float: right;
}
#conversation-group-ptc #conversation-scroll-ptc {
    width: 24px;
    height: calc(313px);
    position: absolute;
    right: 2px;
    padding: 0px 12px 0px 4px;
}
#conversation-group-ptc #conversation-scroll-ptc div {
    width: 6px;
    margin: 0px 1px;
    background: rgb(0, 23, 59);
    opacity: 0;
    position: absolute;
    border-radius: 4px;
    cursor: pointer;
    transition: opacity 0.1s ease-in-out 0s, width 0.1s ease-in-out 0s, margin 0.1s ease-in-out 0s;
    z-index: 2;
    user-select: none;
}
.chat-ptc textarea {
    border: 0px;
    width: 100%;
    font-size: 17px;
    padding: 20px 0px 14px;
    resize: none;
    line-height: 24px;
    overflow-x: hidden;
}
#button.chat-open:not(.sidebar) {
    right: 0px;
    left: auto;
}
#button {
    position: absolute;
    width: 112px;
    height: 140px;
    bottom: 12px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    pointer-events: none;
    z-index: 1;
}
#button:not(.sidebar) .buttonWave {
    position: absolute;
    z-index: -1;
    width: 60px;
    height: 60px;
}
#button-body {
    width: 60px;
    height: 60px;
    border-radius: 28px;
    display: inherit;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    pointer-events: initial;
    /* background-size: 130% 130%; */
    transition: all 0.2s ease-in-out 0s;
    position: relative;
    color: rgb(0, 125, 252);
    background: linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255));
    box-shadow: rgb(0 77 255 / 50%) 0px 4px 24px;
}
.offline-message {
    margin: 18px -28px 0px;
    color: currentcolor;
    width: calc(100% + 56px);
    padding: 14px 28px 7px;
    position: relative;
    background-size: 100% calc(100% + 12px);
    z-index: 1;
    background-image: linear-gradient(135deg, rgba(42, 39, 218, 0.72) 0%, rgba(0, 204, 255, 0.72) 100%);
}
.no-clip-path .offline-message {
    padding: 14px 28px 20px;
}
.offline-message span {
    z-index: 2;
    position: relative;
    display: inline-block;
    font-size: 16px;
}
.offline-message span.online {
    padding-left: 20px;
}
.offline-message span.online::before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    top: calc(50% - 4px);
    background: rgb(88, 183, 67);
    border-radius: 50%;
    left: 0px;
}
.offline-message span {
    z-index: 2;
    position: relative;
    display: inline-block;
    font-size: 16px;
}
/* .no-clip-path .offline-message::after {
    content: "";
    position: absolute;
    width: calc(100% + 10px);
    bottom: -8px;
    left: -5px;
    border-image-source: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNzIgMTUiPgogIDxwYXRoIGQ9Ik0zNDkuOCAxLjRDMzM0LjUuNCAzMTguNSAwIDMwMiAwaC0yLjVjLTkuMSAwLTE4LjQuMS0yNy44LjQtMzQuNSAxLTY4LjMgMy0xMDIuMyA0LjctMTQgLjUtMjggMS4yLTQxLjUgMS42Qzg0IDcuNyA0MS42IDUuMyAwIDIuMnY4LjRjNDEuNiAzIDg0IDUuMyAxMjguMiA0LjEgMTMuNS0uNCAyNy41LTEuMSA0MS41LTEuNiAzMy45LTEuNyA2Ny44LTMuNiAxMDIuMy00LjcgOS40LS4zIDE4LjctLjQgMjcuOC0uNGgyLjVjMTYuNSAwIDMyLjQuNCA0Ny44IDEuNCA4LjQuMyAxNS42LjcgMjIgMS4yVjIuMmMtNi41LS41LTEzLjgtLjUtMjIuMy0uOHoiIGZpbGw9IiNmZmYiLz4KPC9zdmc+Cg==);
    border-image-slice: 0 0 100%;
    border-image-width: 0 0 15px;
    border-image-repeat: stretch;
    border-width: 0px 0px 15px;
    border-bottom-style: solid;
    border-color: initial;
    border-top-style: initial;
    border-left-style: initial;
    border-right-style: initial;
} */
.avatars-wrapper {
    width: 52px;
    height: 52px;
    margin: 0px 18px 0px 0px;
    float: left;
}
.header-ava {
    width: 52px;
    height: 52px;
    border-radius: 24px;
    background-size: cover;
    background-position: center center;
    background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAMDAwMDAwQEBAQFBQUFBQcHBgYHBwsICQgJCAsRCwwLCwwLEQ8SDw4PEg8bFRMTFRsfGhkaHyYiIiYwLTA+PlQBAwMDAwMDBAQEBAUFBQUFBwcGBgcHCwgJCAkICxELDAsLDAsRDxIPDg8SDxsVExMVGx8aGRofJiIiJjAtMD4+VP/CABEIADwAPAMBIgACEQEDEQH/xAAcAAEAAQUBAQAAAAAAAAAAAAAABgEDBAUHAgn/2gAIAQEAAAAA+lYAj8cy5ndHO8VNN0IFrk62gjEYudH9iLQPN6lec+0VmtG038L9UpWh/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAgBAhAAAAAAAP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAIAQMQAAAAAAD/xAAuEAABAwMACAUEAwAAAAAAAAABAgMEAAURBhASEyAhQVEiMmKRwSNxcoExQqH/2gAIAQEAAT8A14PHdbuuMvcMY2wPGs88Z6ClT5qzlUh0n8qjXmawoFay8jqlfwaZebkNIdbOUrGRwCpxJmySf53qtdgJNu+zq+G7N7u4yPUoK9xrsyC3bWfUVL9zw6QxiS1IA5Y2F/Gpplb7qGkeZasCkNpabQhPlQkJH64CMDJ5DvWkk+Gu3uRm3wp5ak42Dkp2TnJNC4S2hhyPvT0Wg4z9xVqmuN3SNJl/TabUcITzxkY2j3piRHkjLLrbg9Ks1gjVL0nnvLVuAllHTllf7Jp+VKknLz7jn5K+KAAGNY8JykkHuDg0xe7rG5JkqUB/VY2x/tR9LkBvEmMsud2/KfeumrtXSu9dKFZNf//EABQRAQAAAAAAAAAAAAAAAAAAAED/2gAIAQIBAT8AB//EABQRAQAAAAAAAAAAAAAAAAAAAED/2gAIAQMBAT8AB//Z);
    float: left;
}
.chat-ptc h2 {
    font-size: 22px;
    font-weight: 600;
    color: currentcolor;
    margin: 4px 0px 0px;
    padding: 0px;
    display: inline-block;
    position: relative;
    /* max-width: calc(100% - 145px); */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: bottom;
}
.chat-ptc h2.twolines {
    margin-bottom: 5px;
}
button.exit-chat, label.exit-chat {
    margin-right: -3px;
    color: rgb(255, 255, 255);
}
button.material-icons::before, label.material-icons::before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: -1;
    transition: all 0.16s ease-in-out 0s;
    transform: scale(0);
    top: calc(50% - 20px);
    left: calc(50% - 20px);
}

.chat-ptc hr {
    margin: 0px;
    border-width: 0px 0px 1px;
    border-top-style: initial;
    border-right-style: initial;
    border-left-style: initial;
    border-top-color: initial;
    border-right-color: initial;
    border-left-color: initial;
    border-image: initial;
    border-bottom-style: solid;
    border-bottom-color: rgb(219, 223, 230);
}

.chat-ptc textarea:focus {
    outline: none;
    border: none;
}

.chat-ptc .input-group {
    padding: 0px 28px 6px;
    width: 100%;
    position: relative;
    background: rgb(255, 255, 255);
    z-index: 3;
    flex: 0 0 auto;
}

.input-group .footer-bottom {
    height: 42px;
    position: relative;
}
.input-group .footer-bottom button {
    float: left;
    margin: 0px 16px 14px 0px;
}
