
.loading-indicator:before {
    content: '';
    background: #fff;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
  }
  
  .loading-indicator:after {
    content: '';
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 10001;
    background-image: url('./loading1.gif'); 
    transform: translate(-50%,-50%);
    width: 207px;
    height: 206px;
  }